.scroller {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.scroller__inner {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

/* Left shadow effect */
.scroller::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 100%;
  z-index: 2;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  pointer-events: none;
}

/* Right shadow effect */
.scroller::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  z-index: 2;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  pointer-events: none;
}

/* Adjustments for dark mode */
.dark .scroller::before,
.dark .scroller::after {
  background: transparent;
}

/* Image color adjustment for dark mode */
.dark .scroller img {
  filter: invert(1) brightness(0.8) grayscale(0.2); /* Adjust colors for dark mode */
}

.scroller[data-animated='true'] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction='right'] {
  --_animation-direction: reverse;
}

.scroller[data-direction='left'] {
  --_animation-direction: forwards;
}

.scroller[data-speed='fast'] {
  --_animation-duration: 20s;
}

.scroller[data-speed='slow'] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translateX(-50%);
  }
}
