.paginationUl li:nth-child(1) button {
  background-color: white !important;
  color: #94a3b8 !important;
}
.paginationUl li:last-child button {
  background-color: white !important;
  color: #94a3b8 !important;
}
.navigation-menu li {
  border-bottom: 1px solid #dbdbdb;
}

@media only screen and (min-width: 1024px) {
  .navigation-menu li {
    border-bottom: none !important;
  }
}
